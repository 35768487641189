import React from "react";
import "../styles/Secondary.css";

const AboutSection = () => (
    <section className="secondary-content-section" id="about-content">
        <h1>About Me</h1>
        <div className="about-me">
            <div className="headshot"></div>
            <div className="about-me-text">
                <p>
                    <strong>Hello!</strong> My name is Brandon Lee, I'm
                    currently pursuing a Bachelor's degree in Computer Science
                    with a focus on Artificial Intelligence at Oregon State
                    University, and I'm expected to graduate in June 2026.
                </p>
                <p>
                    <strong>Why Computer Science?</strong> My passion for
                    computer science began in high school when I took a web
                    design class. I thought it was really fun to build websites
                    and customize them with my own creative touch. What started
                    as an interest quickly grew into a passion as I became
                    fascinated with how technology could be used to solve
                    problems and drive meaningful change.
                </p>
                <p>
                    <strong>Get to Know Me:</strong> I like to stay active by
                    weight training 5-6 days a week, and I also enjoy playing
                    badminton and basketball. When I'm not at the gym or on the
                    court, I love exploring
                    new cuisines and traveling to exciting destinations. So
                    far, I've had the pleasure of visiting Canada, Mexico,
                    Singapore, and Indonesia, and I can't wait to add more
                    countries to my travel list!
                </p>
            </div>
        </div>
    </section>
);

export default AboutSection;
